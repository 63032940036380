import React, { useEffect } from 'react';
import { useStoreContext } from '../../../Store/StoreProvider';
import styles from './CmsTellja.module.scss';

const Tellja = () => {
  const { getStoreConfigFlag, getStoreConfigValue } = useStoreContext();
  const isActive = getStoreConfigFlag('asam_tellja.general.is_active');

  if (!isActive) {
    return null;
  }

  const paramsSufix = getStoreConfigFlag('asam_tellja.general.prod_env') ? 'prod' : 'test';
  const telljaProductCode = getStoreConfigValue(`asam_tellja.general.${paramsSufix}_product_code`);
  const telljaWidgetUrl = getStoreConfigValue(`asam_tellja.general.${paramsSufix}_widget_url`);

  useEffect(() => {
    const existingScript = document.getElementById('tellja');
    let didAppend = false;

    if (!existingScript) {
      const script = document.createElement('script');
      script.src = `${telljaWidgetUrl}widget/button/P${telljaProductCode}?t_art_number=asambeauty&btn=transparent&t_mc=kwk&placementid=telljaBtnContainer`;
      script.async = true;
      script.id = 'tellja';

      document.body.appendChild(script);
      didAppend = true;

      return () => {
        if (didAppend) {
          document.body?.removeChild(script);
        }
      };
    }
  }, []);

  return <div className={styles.Tellja_Container} id="telljaBtnContainer" />;
};

export default Tellja;
